import type { VerticalSliderSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"

import { Button, BUTTON_VARIANT } from "@jobteaser/ui-kit/components/Button"
import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"
import { Text, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { Card } from "@/modules/corporate/components/Card/Card"
import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { RichText } from "@/modules/kontent/components/RichText"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./VerticalSlider.module.css"

const PREFIX = "VerticalSliderSection_"

export type VerticalSliderPropsType = {
  section: VerticalSliderSection
}

export const VerticalSlider: FunctionComponent<VerticalSliderPropsType> = ({ section }) => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const [currentSlideId, setCurrentSlideId] = useState(`${PREFIX}1`)
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  const onObserve: IntersectionObserverCallback = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        setCurrentSlideId(entry.target.id)
      }
    }
  }

  const onTrackEvent = (url: string, label: string): void => {
    trackClickEvent("corporate_cta_slider_vertical", {
      clicked_url: `/${locale}/${URL_PATHNAME}/${url}`,
      visual_context: label,
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onObserve, { threshold: 0.8 })
    let observedValue1: Element | null = null
    let observedValue2: Element | null = null
    let observedValue3: Element | null = null

    if (ref1.current) {
      observer.observe(ref1.current)
      observedValue1 = ref1.current
    }
    if (ref2.current) {
      observer.observe(ref2.current)
      observedValue2 = ref2.current
    }
    if (ref3.current) {
      observer.observe(ref3.current)
      observedValue3 = ref3.current
    }

    return () => {
      if (observedValue1) {
        observer.unobserve(observedValue1)
      }
      if (observedValue2) {
        observer.unobserve(observedValue2)
      }
      if (observedValue3) {
        observer.unobserve(observedValue3)
      }
    }
  }, [])

  return (
    <Layout>
      <Layout.Column className={styles.section}>
        <Text className={styles.title} weight={TEXT_WEIGHTS.BOLD} resetSpacing>
          {section.title}
        </Text>
        <article
          id={`${PREFIX}1`}
          className={clsx(styles.article, { [styles.current]: currentSlideId === `${PREFIX}1` })}
          ref={ref1}
        >
          <div className={styles.medias}>
            {!!section.slide1Image.length && (
              <Image
                src={section.slide1Image[0].url}
                alt={section.slide1Image[0].description || ""}
                width={section.slide1Image[0].width || undefined}
                height={section.slide1Image[0].height || undefined}
                className={styles.image}
                sizes="(min-width: 48rem) 75vw, 100vw"
              />
            )}
          </div>
          <div className={styles.content}>
            <Text
              className={styles.articleTitle}
              type={TEXT_TYPES.HEADING_SMALL}
              weight={TEXT_WEIGHTS.BLACK}
              resetSpacing
            >
              {section.slide1Title}
            </Text>
            <RichText
              value={section.slide1Description}
              wrapperProps={{
                className: styles.text,
                resetSpacing: true,
                tag: "div",
              }}
            />
            {section.ctaTitle && (
              <Text weight={TEXT_WEIGHTS.BOLD} className={styles.ctaTitle} resetSpacing>
                {section.slide3LinksTitle}
              </Text>
            )}
            {!!section.slide1Links.length && (
              <ul className={styles.ctaWrapper}>
                {section.slide1Links.map(link => (
                  <li key={link.title}>
                    <Button
                      variant={BUTTON_VARIANT.GHOST}
                      component={InternalLink}
                      page={link}
                      minor
                      customTitle={link.title}
                      onClick={() => onTrackEvent(link.slug, link.title)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </article>
        <article
          id={`${PREFIX}2`}
          className={clsx(styles.article, { [styles.current]: currentSlideId === `${PREFIX}2` })}
          ref={ref2}
        >
          <div className={styles.medias}>
            {!!section.slide2Image.length && (
              <Image
                src={section.slide2Image[0].url}
                alt={section.slide2Image[0].description || ""}
                width={section.slide2Image[0].width || undefined}
                height={section.slide2Image[0].height || undefined}
                className={styles.image}
                sizes="(min-width: 48rem) 75vw, 100vw"
              />
            )}
          </div>
          <div className={styles.content}>
            <Text
              className={styles.articleTitle}
              type={TEXT_TYPES.HEADING_SMALL}
              weight={TEXT_WEIGHTS.BLACK}
              resetSpacing
            >
              {section.slide2Title}
            </Text>
            <RichText
              value={section.slide2Description}
              wrapperProps={{
                className: styles.text,
                resetSpacing: true,
                tag: "div",
              }}
            />
            {section.ctaTitle && (
              <Text weight={TEXT_WEIGHTS.BOLD} className={styles.ctaTitle} resetSpacing>
                {section.slide2LinksTitle}
              </Text>
            )}
            {!!section.slide2Links.length && (
              <ul className={styles.ctaWrapper}>
                {section.slide2Links.map(link => (
                  <li key={link.title}>
                    <Button
                      variant={BUTTON_VARIANT.GHOST}
                      component={InternalLink}
                      page={link}
                      minor
                      customTitle={link.title}
                      onClick={() => onTrackEvent(link.slug, link.title)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </article>
        <article
          id={`${PREFIX}3`}
          className={clsx(styles.article, { [styles.current]: currentSlideId === `${PREFIX}3` })}
          ref={ref3}
        >
          <div className={styles.medias}>
            {!!section.slide3Image.length && (
              <Image
                src={section.slide3Image[0].url}
                alt={section.slide1Image[0].description || ""}
                width={section.slide3Image[0].width || undefined}
                height={section.slide3Image[0].height || undefined}
                className={styles.image}
                sizes="(min-width: 48rem) 75vw, 100vw"
              />
            )}
          </div>
          <div className={styles.content}>
            <Text
              className={styles.articleTitle}
              type={TEXT_TYPES.HEADING_SMALL}
              weight={TEXT_WEIGHTS.BLACK}
              resetSpacing
            >
              {section.slide3Title}
            </Text>
            <RichText
              value={section.slide3Description}
              wrapperProps={{
                className: styles.text,
                resetSpacing: true,
                tag: "div",
              }}
            />
            {section.ctaTitle && (
              <Text weight={TEXT_WEIGHTS.BOLD} className={styles.ctaTitle} resetSpacing>
                {section.slide3LinksTitle}
              </Text>
            )}
            {!!section.slide3Links.length && (
              <ul className={styles.ctaWrapper}>
                {section.slide3Links.map(link => (
                  <li key={link.title}>
                    <Button
                      variant={BUTTON_VARIANT.GHOST}
                      component={InternalLink}
                      page={link}
                      minor
                      customTitle={link.title}
                      onClick={() => onTrackEvent(link.slug, link.title)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </article>
        <footer className={styles.footer}>
          <Card
            richDescription={section.description}
            ctaLink={!!section.ctaLink.length && section.ctaLink[0]}
            ctaText={section.ctaTitle}
            onCTAClick={() => {
              if (!!section.ctaLink.length && section.ctaLink[0]) {
                onTrackEvent(section.ctaLink[0].slug, section.ctaTitle)
              }
            }}
            theme="light"
          />
        </footer>
      </Layout.Column>
    </Layout>
  )
}
